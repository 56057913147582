.homeHeader {
    font-size: 65px;
    color: #fff;
    font-weight: bold;
    margin: 40px auto;
    text-align: center;
}


.buttonContainer {
    margin: 40px auto;
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.button {
    height: 40px;
    width: 220px;
    border: 1px white solid;
    border-radius: 10px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.button:hover {
    cursor: pointer;
    background-color: #333f44;
}